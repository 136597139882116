<template>
    <div>
      <annuity-list></annuity-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  